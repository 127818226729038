<div class="flex flex-col gap-2 text-sm sm:gap-0.5">
	<label
		class="relative flex items-center gap-2 rounded-input border border-blue-50 px-2 py-2 text-subtle focus-within:bg-blue-50 active:bg-blue-100 sm:border-none sm:px-1 sm:py-1 focus-within:sm:bg-blue-100 hover:sm:bg-blue-50"
		[ngClass]="{
			'pointer-events-none opacity-disabled': control?.disabled
		}"
		*ngFor="let option of options"
	>
		<input
			class="peer absolute appearance-none focus:outline-none"
			type="radio"
			[attr.name]="control?.name || identifier"
			[id]="option.value"
			[value]="option.value"
			[checked]="value === option.value"
			[disabled]="control?.disabled || false"
			[(ngModel)]="value"
			(ngModelChange)="updateValue(value)"
		/>
		<ui-icon
			[name]="
				value === option.value
					? 'radio_button_checked'
					: 'radio_button_unchecked'
			"
			class="select-none rounded-full text-xl leading-none"
			[ngClass]="{
				'text-blue-500 peer-hover:text-blue-700 peer-focus:text-blue-700 peer-active:text-blue-700':
					value === option.value,
				'text-grey-500 peer-hover:text-grey-700 peer-focus:text-grey-700 peer-active:text-grey-700':
					value !== option.value,
				'outline-feedback-error-500':
					showInvalid && control?.invalid && isSubmitted,
				'outline-feedback-success':
					showValid && control?.valid && isSubmitted,
				'outline':
					((showValid && control?.valid) || (showInvalid && control?.invalid)) && isSubmitted,
			}"
		></ui-icon>
		<span class="select-none">
			<ng-container *ngIf="option.options?.translate; else untranslated">
				{{ option.label | translate }}
			</ng-container>
			<ng-template #untranslated>{{ option.label }}</ng-template>
		</span>
	</label>
</div>
