export enum IntakeErrorCodes {
	UNKNOWN = 'ERROR_UNKNOWN',
}

export interface IntakeQuestion {
	id: number;
	question: string;
	answers: Array<IntakeAnswer>;
}

export interface IntakeAnswer {
	id: number;
	answer: string;
	alert?: {
		title: string;
		text: string;
		hasButton?: boolean;
	};
}

export interface DeedIntakeQuestion extends Omit<IntakeQuestion, 'answers'> {
	description: string;
	showOnAnswerID?: number;
	answers: Array<DeedIntakeAnswer>;
}

export interface DeedIntakeAnswer extends IntakeAnswer {
	isDefaultAnswer?: boolean;
	hasButton?: boolean;
}
