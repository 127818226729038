import { inject } from '@angular/core';
import { Route } from '@angular/router';
import { AuthenticationGuard } from './guards/authenticated/authentication.guard';
import { tokenGuard } from './guards/token/token.guard';

export enum RouteNames {
	ACCOUNT_VERIFY = 'account/activeer',
	CONFIRMATION = 'bevestigen',
	INTAKE = 'intake',
	LOGIN = 'login',
	OVERVIEW = 'overzicht',
	PASSWORD_FORGOT = 'wachtwoord-vergeten',
	PASSWORD_SET = 'wachtwoord-instellen',
	QUESTIONNAIRE = 'vragenlijst',
	REGISTER = 'registreren',
}

/* istanbul ignore next */
export const appRoutes: Route[] = [
	{
		path: RouteNames.INTAKE,
		loadChildren: () =>
			import('./pages/intake/intake.module').then(
				(m) => m.IntakePageModule,
			),
		data: {
			hideMenu: true,
		},
		canActivate: [() => inject(AuthenticationGuard).isUnauthenticated()],
	},
	{
		path: RouteNames.QUESTIONNAIRE,
		loadChildren: () =>
			import('./pages/questionnaire/questionnaire.module').then(
				(m) => m.QuestionnairePageModule,
			),
		data: {
			hideMenu: true,
		},
	},
	{
		path: RouteNames.OVERVIEW,
		loadChildren: () =>
			import('./pages/overview/overview.module').then(
				(m) => m.OverviewPageModule,
			),
		data: {
			hideMenu: true,
		},
	},
	{
		path: RouteNames.CONFIRMATION,
		loadChildren: () =>
			import('./pages/confirmation/confirmation.module').then(
				(m) => m.ConfirmationPageModule,
			),
		data: {
			hideMenu: true,
		},
	},
	{
		path: RouteNames.LOGIN,
		loadChildren: () =>
			import('./pages/login/login.module').then((m) => m.LoginPageModule),
		data: {
			hideMenu: true,
			hideHeader: true,
		},
		canActivate: [() => inject(AuthenticationGuard).isUnauthenticated()],
	},
	{
		path: RouteNames.REGISTER,
		loadChildren: () =>
			import('./pages/register/register.module').then(
				(m) => m.RegisterPageModule,
			),
		data: {
			hideMenu: true,
		},
		canActivate: [
			tokenGuard,
			() => inject(AuthenticationGuard).isUnauthenticated(),
		],
	},
	{
		path: RouteNames.ACCOUNT_VERIFY,
		loadChildren: () =>
			import('./pages/account-verify/account-verify.module').then(
				(m) => m.AccountVerifyPageModule,
			),
		data: {
			hideMenu: true,
		},
		canActivate: [() => inject(AuthenticationGuard).isUnauthenticated()],
	},
	{
		path: RouteNames.PASSWORD_FORGOT,
		loadChildren: () =>
			import('./pages/password-forgot/password-forgot.module').then(
				(m) => m.PasswordForgotPageModule,
			),
		data: {
			hideMenu: true,
			hideHeader: true,
		},
		canActivate: [() => inject(AuthenticationGuard).isUnauthenticated()],
	},
	{
		path: RouteNames.PASSWORD_SET,
		loadChildren: () =>
			import('./pages/password-set/password-set.module').then(
				(m) => m.PasswordSetPageModule,
			),
		data: {
			hideMenu: true,
			hideHeader: true,
		},
		canActivate: [() => inject(AuthenticationGuard).isUnauthenticated()],
	},
	{
		path: '**',
		redirectTo: `/${RouteNames.INTAKE}`,
	},
];
