import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, computed, signal } from '@angular/core';
import { ApiErrorHelper } from '@nx/core/lib/helpers/api-error/api-error.helper';
import {
	DeedIntakeQuestion,
	IntakeErrorCodes,
} from '@nx/core/lib/interfaces/intake.interface';
import { DeedIntakeQuestionnaireResponseAPI } from 'api-interfaces';
import { Observable, catchError, map, of } from 'rxjs';

export interface QuestionnaireIntakeBody {
	answers: Array<IntakeAnswer>;
}

export interface IntakeAnswer {
	questionID: number;
	answerID: number;
}

export interface IntakeResponseBody {
	data: {
		token: string;
	};
}

@Injectable({
	providedIn: 'root',
})
export class DeedIntakeService {
	private _intakeLoadingSig = signal<boolean>(false);
	private _intakeLoadedSig = signal<boolean>(false);
	private _intakeErrorSig = signal<string | undefined>(undefined);

	intakeLoadingSig = computed(() => this._intakeLoadingSig());
	intakeLoadedSig = computed(() => this._intakeLoadedSig());
	intakeErrorSig = computed(() => this._intakeErrorSig());

	constructor(
		private readonly http: HttpClient,
		@Inject('apiBaseUrl') private readonly endpoint: string,
	) {}

	postIntake(body: QuestionnaireIntakeBody): Observable<string | undefined> {
		return this.http
			.post<IntakeResponseBody>(
				`${this.endpoint}v1/deed/intake-questionnaire`,
				body,
				{},
			)
			.pipe(
				map((response) => this.handleQuestionSuccess(response)),
				catchError((errorResponse) =>
					this.handleQuestionError(errorResponse),
				),
			);
	}

	private handleQuestionSuccess(response: IntakeResponseBody): string {
		return response.data.token;
	}

	getIntake(): Observable<Array<DeedIntakeQuestion>> {
		this._intakeLoadingSig.set(true);
		this._intakeLoadedSig.set(false);
		return this.http
			.get<DeedIntakeQuestionnaireResponseAPI>(
				`${this.endpoint}v1/deed/intake-questionnaire`,
				{},
			)
			.pipe(
				map((response) => {
					this._intakeLoadingSig.set(false);
					this._intakeLoadedSig.set(true);
					this._intakeErrorSig.set(undefined);
					return this.parseIntake(response);
				}),
				catchError((errorResponse) => {
					const error = ApiErrorHelper.getError<IntakeErrorCodes>(
						errorResponse,
						IntakeErrorCodes,
					);

					this._intakeLoadingSig.set(false);
					this._intakeLoadedSig.set(false);
					this._intakeErrorSig.set(error);
					return of([]);
				}),
			);
	}

	private parseIntake(
		response: DeedIntakeQuestionnaireResponseAPI,
	): Array<DeedIntakeQuestion> {
		if (!response.data) {
			return [];
		}

		return response.data.map((questionItem) => {
			const question: DeedIntakeQuestion = {
				description: questionItem.description,
				id: questionItem.id,
				question: questionItem.question,
				answers: questionItem.answers.map((answerItem) => ({
					id: answerItem.id,
					answer: answerItem.answer,
					alert: answerItem.alert
						? {
								title: answerItem.alert.title,
								text: answerItem.alert.text,
								hasButton: answerItem.alert.button || false,
						  }
						: undefined,
					isDefaultAnswer: answerItem.isDefaultAnswer || false,
				})),
			};

			if (questionItem.showOnAnswerID) {
				question.showOnAnswerID = questionItem.showOnAnswerID;
			}

			return question;
		});
	}

	private handleQuestionError(
		errorResponse: HttpErrorResponse,
	): Observable<undefined> {
		console.log(errorResponse);

		// const error = ApiErrorHelper.getError<QuestionnaireErrorCodes>(
		// 	errorResponse,
		// 	QuestionnaireErrorCodes,
		// );

		// this._questionnaireStateSig.set({
		// 	state: LoadingStates.ERROR,
		// 	error: error,
		// });

		return of(undefined);
	}
}
