import { Component, Inject, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlComponent } from '@nx/core/lib/components/base.control';
import { RadioGroupOption } from './radio-group.interface';

@Component({
	selector: 'ui-radio-group',
	templateUrl: './radio-group.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: RadioGroupComponent,
		},
	],
})
export class RadioGroupComponent extends BaseControlComponent {
	@Input({ required: true }) options!: RadioGroupOption[];

	constructor(@Inject(Injector) override injector: Injector) {
		super(injector);
	}
}
