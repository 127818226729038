import {
	Component,
	OnInit,
	ViewChild,
	ViewEncapsulation,
	effect,
	signal,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { GtmIntakeEvent } from '@nx/core/lib/interfaces/gtm-events.interface';
import { GoogleTagManagerService } from '@nx/core/lib/services/google-tag-manager/google-tag-manager.service';
import {
	DeedIntakeService,
	IntakeAnswer,
	QuestionnaireIntakeBody,
} from 'provider-deed-intake/lib/deed-intake.service';
import { combineLatest } from 'rxjs';
import { ButtonSizes, ButtonStyleTypes } from 'ui-button';
import { DialogTypes } from 'ui-dialog';
import { RadioGroupOption } from 'ui-radio-group';
import { RouteNames } from '../../app.routes';

marker('intake.answers.yes-label');
marker('intake.answers.no-label');

export enum IntakeAnswers {
	yes = 'intake.answers.yes-label',
	no = 'intake.answers.no-label',
}

export const DEED_QUESTIONNAIRE_TOKEN = 'questionnaireIntakeToken';

export interface DeedIntakeQuestionControl {
	id: number;
	question: string;
	answers: Array<DeedIntakeAnswerGroup>;
	showOnAnswerID?: number;
}
export interface DeedIntakeAnswerGroup extends RadioGroupOption {
	id: number;
	alert?: {
		title: string;
		text: string;
		hasButton?: boolean;
	};
	isDefaultAnswer: boolean;
}

@Component({
	selector: 'questionnaire-intake',
	templateUrl: './intake.component.html',
	encapsulation: ViewEncapsulation.None,
})
export class IntakePageComponent implements OnInit {
	@ViewChild('intakeForm') intakeForm?: NgForm;

	readonly buttonSizes = ButtonSizes;
	readonly buttonStyles = ButtonStyleTypes;
	readonly dialogTypes = DialogTypes;
	readonly alertCtaHref =
		'https://www.degoedkoopstenotaris.nl/notarissen?tt=32485_1856416_467255_&r=';

	readonly intakeLoadingSig = this.intakeService.intakeLoadingSig;
	readonly intakeLoadedSig = this.intakeService.intakeLoadedSig;
	readonly intakeErrorSig = this.intakeService.intakeErrorSig;
	questions: Array<DeedIntakeQuestionControl> = [];
	questionData: { [key: string]: string } = {};

	selectedAnswers = signal<number[]>([]);
	isFormDisabled = false;

	constructor(
		private readonly router: Router,
		private readonly intakeService: DeedIntakeService,
		private readonly gtmService: GoogleTagManagerService,
		private readonly translate: TranslateService,
	) {
		effect(() => {
			this.toggleFormEnabled(this.selectedAnswers());
		});
	}

	ngOnInit(): void {
		this.setQuestions();
	}

	toggleFormEnabled(selectedAnswers: number[]): void {
		let isDisabled = false;
		this.questions.forEach((question) => {
			question.answers.forEach((answer) => {
				if (
					answer.alert?.hasButton &&
					selectedAnswers.includes(answer.id)
				) {
					isDisabled = true;
				}
			});
		});
		this.isFormDisabled = isDisabled;
	}

	setQuestions(): void {
		combineLatest([
			this.intakeService.getIntake(),
			this.translate.stream(IntakeAnswers.yes),
			this.translate.stream(IntakeAnswers.no),
		]).subscribe(([data, yesTranslation, noTranslation]) => {
			this.questions = data.map((questionItem) => {
				const question: DeedIntakeQuestionControl = {
					id: questionItem.id,
					question: questionItem.question,
					answers: questionItem.answers.map((answerItem) => ({
						id: answerItem.id,
						label:
							answerItem.answer === 'yes'
								? yesTranslation
								: noTranslation,
						value: answerItem.id.toString(),
						alert: answerItem.alert,
						isDefaultAnswer: answerItem.isDefaultAnswer || false,
					})),
				};

				questionItem.answers.forEach((answer) => {
					if (answer.isDefaultAnswer) {
						this.questionData[
							`${questionItem.id}`
						] = `${answer.id}`;
					}
				});

				if (questionItem.showOnAnswerID) {
					question.showOnAnswerID = questionItem.showOnAnswerID;
				}

				return question;
			});
		});
	}

	updateAnswer(questionId: number, answerId: number | string) {
		if (answerId === 'on' || answerId === 'off') {
			return;
		}
		this.gtmService.push<GtmIntakeEvent>({
			event: `deed-intake-question-${questionId}`,
			answer: answerId,
		});

		const selectedAnswers: number[] = Object.values(this.questionData).map(
			(questionId) => parseInt(questionId),
		);
		this.selectedAnswers.set(selectedAnswers);
	}

	submitQuestionnaire(event: Event) {
		event.preventDefault();
		if (!this.intakeForm || this.intakeForm.invalid) {
			return;
		}

		const body: QuestionnaireIntakeBody = this.parseQuestionData(
			this.questionData,
		);

		this.intakeService.postIntake(body).subscribe((token) => {
			if (!token) {
				return;
			}
			sessionStorage.setItem(DEED_QUESTIONNAIRE_TOKEN, token);
			this.router.navigate([`/${RouteNames.QUESTIONNAIRE}`]);
		});
	}

	private parseQuestionData(questions: {
		[key: string]: string;
	}): QuestionnaireIntakeBody {
		const allAnswers = Object.values(questions);
		const answers: IntakeAnswer[] = [];
		const questionsKeys = Object.keys(questions);

		const optionalQuestions: {
			[key: number]: number;
		} = {};

		this.questions
			.filter((question) => question.showOnAnswerID)
			.forEach((question) => {
				optionalQuestions[question.id] =
					question.showOnAnswerID as number;
			});

		questionsKeys.forEach((key) => {
			const showOnAnswerID = optionalQuestions[parseInt(key)];

			if (!showOnAnswerID || allAnswers.includes(`${showOnAnswerID}`)) {
				answers.push({
					questionID: parseInt(key),
					answerID: parseInt(questions[key]),
				});
			}
		});

		return {
			answers,
		};
	}
}
