<div class="p-6 px-0 sm:px-6">
	<div class="mx-auto max-w-3xl rounded-box bg-white p-8 shadow-box">
		<form
			class="flex flex-col gap-6"
			#intakeForm="ngForm"
			(submit)="submitQuestionnaire($event)"
		>
			<div class="flex flex-col gap-2">
				<h1 class="text-2xl font-bold text-purple-500">
					{{ 'intake.intro.title' | translate }}
				</h1>
				<p>{{ 'intake.intro.text' | translate }}</p>
			</div>
			<div
				*ngIf="intakeLoadingSig()"
				class="flex items-center justify-center"
			>
				<div class="flex items-center gap-2">
					{{ 'intake.loading-text' | translate }}
					<ui-icon
						class="inline-block animate-spin"
						name="refresh"
					></ui-icon>
				</div>
			</div>
			<ui-dialog
				*ngIf="intakeErrorSig()"
				[type]="dialogTypes.ERROR"
				[title]="'intake.error.feedback-title' | translate"
				[text]="'intake.error.feedback-text' | translate"
			>
			</ui-dialog>
			<ng-container
				*ngFor="let question of questions; let questionIndex = index"
			>
				<div
					class="flex flex-col gap-2"
					*ngIf="
						!question.showOnAnswerID ||
						this.selectedAnswers().includes(question.showOnAnswerID)
					"
				>
					<h2 class="text-lg font-bold text-purple-500">
						{{ question.question }}
					</h2>
					<div class="flex flex-col gap-1">
						<ui-radio-group
							[name]="question.id.toString()"
							[options]="question.answers"
							[(ngModel)]="questionData[question.id]"
							(change)="
								updateAnswer(
									question.id,
									questionData[question.id]
								)
							"
							required
						></ui-radio-group>
						<label
							*ngIf="
								intakeForm.submitted &&
								!questionData[question.id]
							"
							class="flex items-start gap-1 text-xs text-feedback-error-500"
						>
							<ui-icon
								name="error"
								class="text-base leading-none text-feedback-error-500"
							></ui-icon>
							<span>{{
								'global.required-feedback-message' | translate
							}}</span>
						</label>
					</div>
					<ng-container *ngFor="let answer of question.answers">
						<div
							class="relative"
							*ngIf="
								answer.alert &&
								questionData[question.id] === answer.value
							"
						>
							<ui-dialog
								[type]="dialogTypes.WARNING"
								[title]="answer.alert.title"
								[text]="answer.alert.text"
								[cta]="answer.alert.hasButton ? {
									text: 'intake.answer.cta-text' | translate,
									href: alertCtaHref,
								} : undefined"
							></ui-dialog>
						</div>
					</ng-container>
				</div>
			</ng-container>
			<div *ngIf="!intakeLoadingSig() && !intakeErrorSig()">
				<ui-button
					[size]="buttonSizes.L"
					[styleType]="buttonStyles.CTA"
					[isSubmit]="true"
					[isDisabled]="isFormDisabled"
				>
					{{ 'intake.submit-action' | translate }}
				</ui-button>
			</div>
		</form>
	</div>
</div>
