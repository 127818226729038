import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { RouteNames } from '../../app.routes';
import { DEED_QUESTIONNAIRE_TOKEN } from '../../pages/intake/intake.component';

export const tokenGuard: CanActivateFn = () => {
	const router = inject(Router);

	if (!sessionStorage.getItem(DEED_QUESTIONNAIRE_TOKEN)) {
		router.navigate([`/${RouteNames.INTAKE}`]);
		return false;
	}

	return true;
};
